<template>
  <div>

    <section class="full-width">
      <nav class="paginaZuccheri__navbar">
        <ul class="paginaZuccheri__navbar__list"></ul>
      </nav>
      <div class="paginaZuccheri__wrapper" :style="`--zuccheriMainColor:${currentColor};--zuccheriSecondaryColor:${currentLightColor}`">
        <header>
          <!-- STICKY NAVBAR -->
        </header>

        <!-- PRODUCTS CAROUSAL -->
        <section class="hero_section carousal">
          <div
            class="hero_section_content"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="400"
            data-aos-easing="linear"
            data-aos-offset="10"
            data-aos-anchor-placement="top-bottom"
          >
            <h1>Il nostro zucchero è buono in tutti i sensi</h1>
            <p>I nostri zuccheri equosolidali sono buoni per chi lavora,<br class="hide_mobile">per le terre d'origine e per te che li provi.</p>
            <p>Esplora le specificità di ognuno di loro e scopri le caratteristiche<br class="hide_mobile">che possiede uno zucchero buono in tutti i sensi.</p>
          </div>

          <!-- Sliding Carousel -->
          <div
            class="path-slider"
            id="path-slider"
            data-aos="fade-in"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-offset="10"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
          >
            <!-- Slider items inserted via JavaScript-->
          </div>
          <section class="slider-arrows">
            <div class="slider-prev">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z"
                />
              </svg>
            </div>

            <div class="slider-next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"
                />
              </svg>
            </div>
          </section>
        </section>

        <!-- SECTION 1 - WEB VIEW -->
        <section class="section-1" id="svg_lines_section">
          <div class="section-1-image-container">
            <img
              src=""
              alt="product-image.png"
              data-aos="fade-in"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-easing="linear"
              data-aos-offset="10"
              data-aos-achor-placement="top-bottom"
              data-aos-once="true"
            />

            <div class="main-image__arrow1 position">
              <div style="position: relative">
                <!-- ENVELOP -->
                <div
                  class="main-img-content main-img-content1"
                  data-aos="fade-in"
                  data-aos-delay="800"
                  data-aos-duration="300"
                  data-aos-easing="linear"
                  data-aos-offset="10"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-once="true"
                >
                  <div class="image_icon">
                    <img src="" alt="" />
                  </div>
                  <a class="zuccheriButton"></a>
                  <p></p>
                </div>

                <!-- LINE -->
                <svg
                  width="280"
                  height="185"
                  viewBox="0 0 333 185"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="9" cy="9" r="9" fill="none" />
                  <path
                    d="M331.5 183L94 9.5H18"
                    :stroke="currentColor"
                    stroke-width="4"
                  />
                </svg>
              </div>
            </div>

            <div class="main-image__arrow2 position">
              <div
                class="main-img-content main-img-content2"
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-duration="300"
                data-aos-easing="linear"
                data-aos-offset="10"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="true"
              >
                <div class="image_icon">
                  <img src="" alt="" />
                </div>
                <a class="zuccheriButton"></a>
                <p></p>
              </div>

              <svg
                width="300"
                height="247"
                viewBox="0 0 298 247"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="289" cy="9" r="9" fill="none" />
                <path
                  d="M2 245.5L214.5 9.5H288.5"
                  :stroke="currentColor"
                  stroke-width="4"
                />
              </svg>
            </div>

            <div class="main-image__arrow3 position">
              <div
                class="main-img-content main-img-content3"
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-duration="300"
                data-aos-easing="linear"
                data-aos-offset="10"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="true"
              >
                <div class="image_icon">
                  <img src="" alt="" />
                </div>
                <a class="zuccheriButton"></a>
                <p></p>
              </div>

              <svg
                width="210"
                height="148"
                viewBox="0 0 203 148"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="39" r="9" fill="none" />
                <path d="M202 96L14 40.5" :stroke="currentColor" stroke-width="4" />
              </svg>
            </div>

            <div class="main-image__arrow4 position">
              <div
                class="main-img-content main-img-content4"
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-duration="300"
                data-aos-easing="linear"
                data-aos-offset="10"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="true"
              >
                <div class="image_icon">
                  <img src="" alt="" />
                </div>
                <a class="zuccheriButton"></a>
                <p></p>
              </div>
              <svg
                width="277"
                height="194"
                viewBox="0 0 277 194"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="185" r="9" fill="none" />
                <path
                  d="M275.5 1.5L147 184.5H9.5"
                  :stroke="currentColor"
                  stroke-width="4"
                />
              </svg>
            </div>

            <div class="main-image__arrow5 position">
              <div
                class="main-img-content main-img-content5"
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-duration="300"
                data-aos-easing="linear"
                data-aos-offset="10"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="true"
              >
                <div class="image_icon">
                  <img src="" alt="" />
                </div>
                <a class="zuccheriButton"></a>
                <p></p>
              </div>

              <svg
                width="250"
                height="116"
                viewBox="0 0 318 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="309" cy="107" r="9" fill="none" />
                <path d="M1 1.5L313.5 108" :stroke="currentColor" stroke-width="4" />
              </svg>
            </div>
          </div>

          <a
            href=""
            class="product-main-btn"
            @click="e => {e.preventDefault();$router.push(e.currentTarget.dataset.link);}"
          ></a>
        </section>

        <!-- SECTION 1 - MOBILE VIEW -->
        <section class="section-1-mobile-view">
          <div class="section-1-product-img">
            <img
              src=""
              alt="section-1-product-img"
              data-aos="fade-in"
              data-aos-delay="50"
              data-aos-duration="1500"
              data-aos-easing="linear"
              data-aos-offset="10"
              data-aos-achor-placement="top-bottom"
              data-aos-once="true"
            />
          </div>
          <VueSlickCarousel class="product_mobile_carousal" v-bind="product_mobile_carousal_settings" v-if="product_mobile_carousal.length">
            <div class="section-1-view-design" v-for="item in product_mobile_carousal" v-bind:key="item.image">
              <div>
                <div class="section-1-product-icon">
                  <img :src="item.image">
                </div>
                <a class="section-1-product-btn">{{item.title}}</a>
                <div class="section-1-product-text" v-html="item.description"></div>
              </div>
            </div>
          </VueSlickCarousel>
          <a href="" class="product-main-btn"></a>
        </section>

        <!-- SECTION 2 -->
        <section class="section-2 chart-section">
          <div
            class="chart"
            data-aos="fade-in"
            data-aos-delay="10"
            data-aos-duration="400"
            data-aos-easing="linear"
            data-aos-offset="10"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
          >
            <canvas id="myChart"></canvas>
          </div>
          <div
            class="details"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="500"
            data-aos-easing="linear"
            data-aos-offset="100"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
          >
            <h2></h2>
            <p></p>

            <p class="read-more-target"></p>

            <button class="read-more-button">
              <span>LEGGI DI PIÙ</span>
              <i class="fa fa-sharp fa-solid fa-chevron-down"></i>
            </button>
          </div>
        </section>

        <!-- SECTION 3 -->
        <section class="section-3">
          <div class="section-3-product-img">
            <img src="" alt="" />
          </div>
          <h1></h1>
        </section>

        <!-- SECTION 4 -->
        <section class="section-4">
          <div class="section-4-text">
            <h3></h3>
            <p></p>
          </div>
        </section>

        <!-- SECTION 5 - WEB VIEW -->
        <section class="section-5">
          <img
            src=""
            alt=""
            data-aos="fade"
            data-aos-delay="10"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            data-aos-offset="100"
            data-aos-anchor-placement="top-bottom"
          />
        </section>

        <!-- SECTION 5 - MOBILE VIEW -->
        <div class="section-5-mobile-view">
          <VueSlickCarousel class="slides" v-bind="slides_settings" v-if="slides.length">
            <div v-for="item in slides" v-bind:key="item.mobileImage">
              <div class="mb-img">
                <img :src="item.topImage">
              </div>
              <span class="mb-heading">AROMA</span>
              <img :src="item.image" class="section-5-mobile-view-img">
            </div>
          </VueSlickCarousel>
        </div>

        <!-- SECTION 6 -->
        <section class="section-2 section-6">
          <div class="image-grid">
            <img src="" alt="" class="section-6-img" />
          </div>
          <div class="details">
            <h2></h2>
            <p></p>
            <p class="read-more-target"></p>
            <button class="read-more-button">
              <span>LEGGI DI PIÙ</span>
              <i class="fa fa-sharp fa-solid fa-chevron-down"></i>
            </button>

            <a href="" class="section-6-button" @click="e => {e.preventDefault();$router.push(e.currentTarget.dataset.link);}"></a>
          </div>
        </section>
      </div>
    </section>

  </div>
</template>

<script>
// import Vue from 'vue'
import PaginaZuccheri, { AOS } from '@/assets/paginaZuccheri/main.js'
import VueSlickCarousel from 'vue-slick-carousel'
import axios from 'axios'

export default {
  name : 'PaginaZuccheri',
  data(){
    return {
      product_mobile_carousal: [],
      product_mobile_carousal_settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        loop: true,
        arrows: false,
        infinite: true,
        mobileFirst: true,
        variableWidth: true
      },
      slides: [],
      slides_settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        loop: true,
        arrows: false,
        infinite: true,
        mobileFirst: true,
      },
      pagine: [
        {
          name: "Fior di cocco",
          color: "#1768B1",
          lightColor: "#c7d8eb",
          wp_id: 19573,
          slug: "fior-di-cocco",
          shop: "/product/00001196/zucchero-integrale-di-cocco-fior-di-cocco-bio-250g"
        },
        {
          name: "Dulcita",
          color: "#C51315",
          lightColor: "#fef4f5",
          wp_id: 19575,
          slug: "dulcita",
          shop: "/product/00000073/zucchero-di-canna-dulcita-integrale-ecuador-bio-500g"
        },
        {
          name: "Mascobado",
          color: "#90BF21",
          lightColor: "#e3eeca",
          wp_id: 19521,
          slug: "mascobado",
          shop: "/product/00000156/zucchero-integrale-di-canna-mascobado-filippine-bio-500g"
        },
        {
          name: "Demerara",
          color: "#173267",
          lightColor: "#f6f9ff",
          wp_id: 19577,
          slug: "demerara",
          shop: "/product/00000525/zucchero-grezzo-di-canna-in-cristalli-demerara"
        },
        {
          name: "Picaflor",
          color: "#775339",
          lightColor: "#fdf5ef",
          wp_id: 19579,
          slug: "picaflor",
          shop: "/product/00000331/zucchero-di-canna-picaflor-grezzo-paraguay-bio-500g"
        },
        {
          name: "Manduvirà",
          color: "#204D43",
          lightColor: "#f3fffc",
          wp_id: 19581,
          slug: "manduvira",
          shop: "/product/00001156/zucchero-di-canna-chiaro-manduvir-bio-500g"
        },
      ],
      currentColor: '',
      currentLightColor: '',
      pageTitle: false,
    }
  },

  components: {
    VueSlickCarousel
  },

  watch: {
    '$route.params.slug': function() {
      const pagina = this.pagine.find(p => p.slug === this.$route.params.slug)
      if(!pagina) return this.$router.push('/')
      this.loadPagina(pagina)
      this.scrollToContent()
    }
  },

  metaInfo () {
    this.$utils.adabraPageView(this, { pageType: 108, pageTitle: this.pageTitle === false ? 'Zuccheri' : this.pageTitle });
    return {
      title: this.pageTitle === false ? 'Zuccheri' : this.pageTitle,
    }
  },

  async mounted(){
    await this.initPage()
  },

  beforeDestroy() {
  },

  methods: {
    scrollToContent(behavior = "smooth") {
      window.scrollTo({
        top: Math.max(document.querySelector('#svg_lines_section').offsetTop - 240, document.querySelector('.section-1-mobile-view').offsetTop - 100),
        behavior,
      })
    },
    loadPagina(pagina, firstLoad = false) {
      if(!firstLoad) {
        document.querySelectorAll(".paginaZuccheri__navbar__list a.active")?.forEach($el => $el.classList.remove("active"))
        document.querySelector(`.paginaZuccheri__navbar__list a[href="/zuccheri/${pagina.slug}"]`).classList.add("active")
      }
      this.product_mobile_carousal = pagina.s1.product_mobile_carousal;
      PaginaZuccheri.renderSection1({
        productImage: pagina.s1.productImage,
        btnText: `VAI A ${pagina.name.toUpperCase()}`,
        btnLink: pagina.shop,
        list: pagina.s1.product_mobile_carousal
      });

      if(pagina.s2.chartData) {
        PaginaZuccheri.renderSection2Chart(pagina.s2.chartLabels, pagina.s2.chartData, pagina.color, pagina.lightColor);

        PaginaZuccheri.renderSection2Description(pagina.s2.title, [pagina.s2.firstParagraph, pagina.s2.otherParagraphs]);
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.visibility = '';
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.height = '';
        document.querySelector('.paginaZuccheri__wrapper .section-3').style.backgroundColor = "";
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.padding = "";
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.margin = "";
      } else {
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.padding = 0;
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.margin = 0;
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.visibility = 'hidden';
        document.querySelector('.paginaZuccheri__wrapper .section-2').style.height = 0;
        document.querySelector('.paginaZuccheri__wrapper .section-3').style.backgroundColor = "#f5f5f5";
      }

      PaginaZuccheri.renderSection3(pagina.s3.image, pagina.s3.title);

      if(pagina.s4.title && pagina.s4.text) {
        PaginaZuccheri.renderSection4(pagina.s4.title, pagina.s4.text);
        document.querySelector('.paginaZuccheri__wrapper .section-4').style.display = '';
      } else {
        document.querySelector('.paginaZuccheri__wrapper .section-4').style.display = 'none';
      }

      if(pagina.s5.image) {
        PaginaZuccheri.renderSection5({ image: pagina.s5.image });
        document.querySelector('.paginaZuccheri__wrapper .section-5').style.display = '';
      } else {
        document.querySelector('.paginaZuccheri__wrapper .section-5').style.display = 'none';
      }
      this.slides_settings.dots = false;
      requestAnimationFrame(() => {
        this.slides_settings.dots = true;
      })
      this.slides = pagina.s5.slides;

      PaginaZuccheri.renderSection6({
        image: pagina.s6.image,
        title: pagina.s6.title,
        descriptions: [ pagina.s6.firstParagraph, pagina.s6.otherParagraphs ],
        btnText: `VAI A ${pagina.name.toUpperCase()}`,
        btnLink: pagina.shop
      });

      AOS.init();

      this.currentColor = pagina.color;
      this.currentLightColor = pagina.lightColor;

      this.pageTitle = pagina.pageTitle;
      this.$meta().refresh();
    },
    async initPage() {
      const render = (s) => s ? s.replace(/\[\[(\/?(em|strong))\]\]/gi, '<$1>') : ''
      await axios.all(this.pagine.map(p => axios.get(`https://altromercato.it/wp-json/wp/v2/pages/${p.wp_id}/?_embed`)))
        .then((res) => {
          res.forEach(r => {
            const wp_id = r.data.id
            const renderedContent = document.createElement('div')
            const currentPage = this.pagine.find(p => p.wp_id === wp_id)
            renderedContent.innerHTML = r.data.content.rendered.replace(/<(\/?(em|strong))[^>]*>/gi, '[[$1]]')
            currentPage.image = renderedContent.querySelector('#s1-package img')?.getAttribute('src')
            currentPage.link = `/zuccheri/${currentPage.slug}`
            currentPage.s1 = {
              product_mobile_carousal: [1, 4, 2, 3, 5].map(id => {
                return {
                  id,
                  image: renderedContent.querySelector(`#s1-img${id} img`)?.getAttribute('src'),
                  title: render(renderedContent.querySelector(`#s1-title${id}`)?.innerText).toUpperCase(),
                  description: render(renderedContent.querySelector(`#s1-text${id}`)?.innerText),
                }
              }),
              productImage: currentPage.image,
            }
            currentPage.s2 = {
              chartLabels: renderedContent.querySelector(`#s2-chart`)?.dataset?.x_axis_labels?.split('|'),
              chartData: renderedContent.querySelector(`#s2-chart [data-label="dati"]`)?.dataset?.values?.split('|'),
              title: renderedContent.querySelector(`#s2-title p, #s2-title`)?.innerHTML,
              firstParagraph: renderedContent.querySelector(`#s2-text p`) ? render(Array.from(renderedContent.querySelectorAll(`#s2-text p`)).map(p => p.innerText).join('<br><br>')) : '',
              otherParagraphs: "",
            }
            currentPage.s3 = {
              title: render(renderedContent.querySelector(`#s3-title`)?.innerText),
              image: renderedContent.querySelector(`#s3-img img`)?.getAttribute('src'),
            }
            currentPage.s4 = {
              title: render(renderedContent.querySelector(`#s4-title`)?.innerText),
              text: renderedContent.querySelector(`#s4-text`)?.innerHTML,
            }
            const s5mImg = renderedContent.querySelector(`#s5m-img img`)?.getAttribute('src');
            currentPage.s5 = {
              image: renderedContent.querySelector(`#s5-img img`)?.getAttribute('src'),
              slides: Array.from(renderedContent.querySelectorAll(`#s5m-carousel img`)).map(s => ({ topImage: s5mImg, image: s.getAttribute('src'), title: s.getAttribute('alt') })),
            }
            const s6paragraphs = Array.from(renderedContent.querySelectorAll(`#s6-text p`))
            currentPage.s6 = {
              image: renderedContent.querySelector(`#s6-img img`).getAttribute('src'),
              title: render(renderedContent.querySelector(`#s6-title`)?.innerText).replace(/[\n\r]+/g, '<br>'),
              firstParagraph: s6paragraphs.length ? render(s6paragraphs[0]?.innerText) : '',
              otherParagraphs: s6paragraphs.slice(1).map(p => render(p.innerText)).join('<br><br>'),
            }
            currentPage.pageTitle = (() => {
              const t = document.createElement('textarea')
              t.innerHTML = r.data?.title?.rendered
              return t.value
            })();
          })

          const pagina = this.pagine.find(p => p.slug === this.$route.params.slug)
          if(!pagina) return this.$router.push('/')

          const genericLoad = (location.hash !== `#${pagina.slug}`)

          PaginaZuccheri.renderStickyHeaderContent(this.pagine.map(p => ({
            name: p.name,
            link: p.link,
            clickCallback: e => {
              e.preventDefault()
              if(p.link === this.$route.path) {
                this.scrollToContent()
                document.querySelectorAll(".paginaZuccheri__navbar__list a.active")?.forEach($el => $el.classList.remove("active"))
                const pagina = this.pagine.find(p => p.slug === this.$route.params.slug)
                document.querySelector(`.paginaZuccheri__navbar__list a[href="/zuccheri/${pagina.slug}"]`).classList.add("active")
              } else {
                this.$router.push(p.link)
              }
            }
          })))

          PaginaZuccheri.renderProductsCarousal(this.pagine.map((p, i) => ({
            id: i + 1,
            name: p.name,
            backgroundColor: p.color,
            image: p.image,
            clickCallback: e => {
              e.preventDefault()
              if(p.link === this.$route.path) {
                this.scrollToContent()
                document.querySelectorAll(".paginaZuccheri__navbar__list a.active")?.forEach($el => $el.classList.remove("active"))
                const pagina = this.pagine.find(p => p.slug === this.$route.params.slug)
                document.querySelector(`.paginaZuccheri__navbar__list a[href="/zuccheri/${pagina.slug}"]`).classList.add("active")
              } else {
                this.$router.push(p.link)
              }
            }
          })), genericLoad ? false : this.pagine.findIndex(p => p.slug === this.$route.params.slug) + 1)

          this.loadPagina(pagina, genericLoad)

          // if(!genericLoad) {
          //   this.scrollToContent("instant")
          // }

        })
    }
  }
}
</script>

<style lang="scss" src="@/assets/paginaZuccheri/paginaZuccheri.scss"></style>
