import anime from "./anime.min.js";
import PathSlider from "./path-slider.js";
import AOS from "./aos.js";
import Chart from "./chart.min.js";
import ChartDeferred from "./chartjs-plugin-deferred.min.js";
import getSinPath from "./get-sin-path.js";

const getFontSize = () => {
	const screenWidth = window.innerWidth;
	if (screenWidth < 375) return 9;
	if (screenWidth < 425) return 9;
	if (screenWidth < 1024) return 10;
	return 11;
};

let stopCarouselAndSetSlide = () => {};

function renderCircularCarousal(selectedProduct = false) {
	// Creating SVG and path elements and insert to DOM

	var svgNS = "http://www.w3.org/2000/svg";
	var svgEl = document.createElementNS(svgNS, "svg");

	var pathEl = document.createElementNS(svgNS, "path");
	// The `getSinPath` function return the `path` in String format
	pathEl.setAttribute("d", getSinPath());
	pathEl.setAttribute("class", "path-slider__path");

	svgEl.appendChild(pathEl);
	document.querySelector(".path-slider").appendChild(svgEl);

	let itemsArray = Array.from(
		document.querySelectorAll(".path-slider__item")
	);
	var images = [];

	var imgAnimation;
	var lastIndex;
	var setImage = function (index) {
		if (imgAnimation) {
			imgAnimation.pause();
			sliderContainer.style["background-image"] = images[lastIndex];
			sliderContainerBackground.style["opacity"] = 0;
		}
		lastIndex = index;
		sliderContainerBackground.style["background-image"] = images[index];
		imgAnimation = anime({
			targets: sliderContainerBackground,
			opacity: 1,
			easing: "linear",
		});
	};

	// Adding the extra element needed to fade the images smoothly
	// Also set the image for the initial current item (the first one)

	var sliderContainer = document.querySelector(".path-slider");
	var sliderContainerBackground = document.createElement("div");
	sliderContainerBackground.setAttribute("class", "path-slider__background");
	setImage(0);
	sliderContainer.appendChild(sliderContainerBackground);

	// Initializing the slider

	function calculateActiveSeparation(screenSize) {
		if (screenSize <= 425) return undefined;
		if (screenSize <= 768) return (screenSize * 35) / 100;
		if (screenSize <= 820) return (screenSize * 35) / 100;
		if (screenSize <= 1024) return (screenSize * 28) / 100;
		if (screenSize <= 1080) return (screenSize * 33) / 100;
		if (screenSize <= 1200) return (screenSize * 31) / 100;
		if (screenSize <= 1360) return (screenSize * 30) / 100;
		if (screenSize <= 1500) return (screenSize * 29) / 100;
		if (screenSize <= 1800) return (screenSize * 27) / 100;
		if (screenSize <= 2000) return (screenSize * 25) / 100;
	}

	function calculatePaddingSeparation(screenSize) {
		if (screenSize <= 1024) return (screenSize * 25) / 100;
		if (screenSize <= 1280) return (screenSize * 15) / 100;
		return (screenSize * 10) / 100;
	}

	var options = {
		startLength: "center",
		paddingSeparation: calculatePaddingSeparation(window.innerWidth),
		activeSeparation: calculateActiveSeparation(window.innerWidth),
		mobilePaddingSeparation: 1,
		easing: "easeOutCubic",
	};

	class IntervalTimer {
		callbackStartTime;
		remaining = 0;
		paused = false;
		timerId = null;
		_callback;
		_delay;
		stopped = false;

		constructor(callback, delay) {
			this._callback = callback;
			this._delay = delay;
		}

		pause() {
			if (!this.paused) {
				this.clear();
				this.remaining = new Date().getTime() - this.callbackStartTime;
				this.paused = true;
			}
		}

		resume() {
			if (this.paused) {
				if (this.remaining) {
					setTimeout(() => {
						this.run();
						this.paused = false;
						this.start();
					}, this.remaining);
				} else {
					this.paused = false;
					this.start();
				}
			}
		}

		clear() {
			clearInterval(this.timerId);
		}

		start() {
			if(!this.stopped) {
				this.clear();
				this.timerId = setInterval(() => {
					this.run();
				}, this._delay);
			}
		}

		run() {
			this.callbackStartTime = new Date().getTime();
			this._callback();
		}
	}

	var slider = new PathSlider(pathEl, ".path-slider__item", options);
	let isDragging = false;
	let startPos = 0;

	const interval = new IntervalTimer(() => slider.selectNextItem(), 3000);
	interval.start();

	const pauseTimer = () => {
		interval.clear();
	};

	const startTimer = () => {
		interval.start();
	};

	const stopTimer = () => {
		interval.stopped = true;
		interval.clear();
	};

	stopCarouselAndSetSlide = (index) => {
		stopTimer();
		slider.selectItem(index);
	};

	document.querySelectorAll(".path-slider__item").forEach($el => $el.addEventListener("click", e => {
		stopCarouselAndSetSlide(e.currentTarget.dataset.id - 1);
	}))

	itemsArray.map((item) => {
		item.addEventListener("mouseover", pauseTimer);
		item.addEventListener("mouseleave", startTimer);
	});

	// Regenerate the SVG `path` and update items position on `resize` event (responsive behavior)

	window.addEventListener("resize", function () {
		pathEl.setAttribute("d", getSinPath());
		slider.updatePositions();
	});

	// Add Dragging and Swiping Feature to Carousel

	const svgPath = document.querySelector(".path-slider");

	function getPositionX(event) {
		return event.type.includes("pointer")
			? event.clientX
			: event.touches[0].clientX;
	}

	const dragElements = (e) => {
		e.type.includes("pointer") ? e.preventDefault() : null;
		if (isDragging) {
			interval.clear();
			let dragPosition = getPositionX(e);
			let movedBy = dragPosition - startPos;
			if (movedBy >= 40) {
				slider.selectPrevItem();
				isDragging = false;
			}
			if (movedBy < -40) {
				slider.selectNextItem();
				isDragging = false;
			}
		}
	};

	svgPath.addEventListener("pointerdown", (e) => {
		isDragging = true;
		startPos = e.clientX;
		interval.clear();
	});

	svgPath.addEventListener("touchstart", (e) => {
		isDragging = true;
		startPos = e.touches[0].clientX;
		interval.clear();
	});

	svgPath.addEventListener("dragstart", (e) => e.preventDefault());

	svgPath.addEventListener("touchmove", dragElements);

	svgPath.addEventListener("pointermove", dragElements);

	svgPath.addEventListener("pointerup", () => {
		isDragging = false;
		interval.start();
	});

	svgPath.addEventListener("touchend", () => {
		isDragging = false;
		interval.start();
	});

	document.querySelector(".slider-next").addEventListener("click", () => {
		pauseTimer();
		slider.selectNextItem();
		startTimer();
	});

	document.querySelector(".slider-prev").addEventListener("click", () => {
		pauseTimer();
		slider.selectPrevItem();
		startTimer();
	});

	if(selectedProduct) {
		stopCarouselAndSetSlide(selectedProduct - 1);
	}
}

function renderSvgLinesWithAnimation(SVGLinesSection, svgSectionpos) {
	if (window.pageYOffset >= svgSectionpos) {
		const nodes = Array.from(SVGLinesSection.getElementsByTagName("svg"));
		nodes.forEach((node) => {
			node.style.animation = "animateDash 2.0s linear forwards 0.5s";
			node.firstElementChild.style.animation =
				"animateCircle 1.5s linear forwards 0.6s";
		});
	}
}

let readMoreAdded = [];

function renderReadMore(parentClass = "") {
	if(readMoreAdded.includes(parentClass)) return;
	readMoreAdded.push(parentClass);
	const readMoreBtn = document.querySelector(
		parentClass + " .read-more-button"
	);
	const readMoreTarget = document.querySelector(
		parentClass + " .read-more-target"
	);

	readMoreBtn.addEventListener("click", () => {
		if (readMoreTarget.style.display === "block") {
			document.querySelector(
				parentClass + " .read-more-button span"
			).innerHTML = "LEGGI DI PIÙ";

			readMoreTarget.style.display = "none";

			document
				.querySelector(parentClass + " .read-more-button i")
				.classList.remove("fa-chevron-up");
			document
				.querySelector(parentClass + " .read-more-button i")
				.classList.add("fa-chevron-down");
		} else {
			document.querySelector(
				parentClass + " .read-more-button span"
			).innerHTML = "LEGGI DI MENO";

			readMoreTarget.style.display = "block";

			document
				.querySelector(parentClass + " .read-more-button i")
				.classList.remove("fa-chevron-down");
			document
				.querySelector(parentClass + " .read-more-button i")
				.classList.add("fa-chevron-up");
		}
	});
}


window.addEventListener("scroll", function () {
	const SVGLinesSection = document.querySelector("#svg_lines_section");
	if(SVGLinesSection) {
		const svgSectionpos = SVGLinesSection.offsetTop - 700;
		renderSvgLinesWithAnimation(SVGLinesSection, svgSectionpos);
	}
});

class DMCommerce {
	static chart = null;

	static renderStickyHeaderContent(products = []) {
		const stickyNavBar = document.querySelector(".paginaZuccheri__navbar__list");

		products.forEach((product, i) => {
			const listItem = document.createElement("li");
			const button = document.createElement("a");

			button.innerHTML = product.name;
			button.href = product.link;
			button.addEventListener("click", e => {
				product.clickCallback(e)
				stopCarouselAndSetSlide(i)
			});

			listItem.appendChild(button);
			stickyNavBar.appendChild(listItem);
		});
	}

	// carousal section
	static renderProductsCarousal(products = [], selectedProduct = false) {
		const heroSectionSlider = document.querySelector(".path-slider");

		heroSectionSlider.replaceChildren([]);

		products.forEach((product) => {
			const sliderItem = document.createElement("div");
			const innerSliderItemDiv = document.createElement("div");
			const productBackgroundDiv = document.createElement("div");
			const productImageContainerDiv = document.createElement("div");
			const productImage = document.createElement("img");
			const productName = document.createElement("h2");

			sliderItem.classList.add("path-slider__item");
			sliderItem.dataset.id = product.id;
			productBackgroundDiv.classList.add("background");
			productBackgroundDiv.style.backgroundColor = product.backgroundColor;
			productImageContainerDiv.classList.add("item__circle");
			productImage.src = product.image;
			productName.innerHTML = product.name.toUpperCase();

			productImageContainerDiv.appendChild(productImage);
			innerSliderItemDiv.appendChild(productBackgroundDiv);
			innerSliderItemDiv.appendChild(productImageContainerDiv);
			innerSliderItemDiv.appendChild(productName);
			innerSliderItemDiv.addEventListener("click", product.clickCallback);
			sliderItem.appendChild(innerSliderItemDiv);

			heroSectionSlider.appendChild(sliderItem);
		});

		renderCircularCarousal(selectedProduct);
	}

	// section - 1
	static renderSection1({
		productImage = "",
		btnText = "",
		btnLink = "",
		list = [],
	}) {
		// web
		const productImageNode = document.querySelector(
			".section-1 .section-1-image-container > img"
		);
		const btnNode = document.querySelector(".section-1 .product-main-btn");

		productImageNode.src = productImage;
		btnNode.innerHTML = btnText;
		btnNode.href = btnLink;
		btnNode.dataset.link = btnLink;

		list.forEach((item, index) => {
			const listNode1Image = document.querySelector(
				`.section-1 .main-img-content${index + 1} img`
			);
			const listNode1title = document.querySelector(
				`.section-1 .main-img-content${index + 1} a`
			);
			const listNode1Description = document.querySelector(
				`.section-1 .main-img-content${index + 1} p`
			);

			listNode1Image.src = item.image;
			listNode1title.innerHTML = item.title;
			listNode1Description.innerHTML = item.description;
		});

		document.querySelector(".section-1").style.display = "block";

		const productImageMobileNode = document.querySelector(
			".section-1-mobile-view .section-1-product-img > img"
		);
		const btnMobileNode = document.querySelector(
			".section-1-mobile-view .product-main-btn"
		);
		productImageMobileNode.src = productImage;
		btnMobileNode.innerHTML = btnText;
		btnMobileNode.href = btnLink;
		btnMobileNode.dataset.link = btnLink;

	}

	// section - 2
	static renderSection2Chart(labels = [], data = [], mainColor, secondaryColor) {
		Chart.register(ChartDeferred);
		if(labels[2] && labels[2].split) {
			labels[2] = labels[2].split(" ")
		}
		const chatData = {
			labels,
			datasets: [
				{
					data,
					fill: true,
					backgroundColor: secondaryColor,
					borderColor: mainColor,
					pointBackgroundColor: mainColor,
					pointBorderColor: "#fff",
					pointHoverBackgroundColor: "#fff",
					pointHoverBorderColor: mainColor,
				},
			],
		};

		const config = {
			type: "radar",
			data: chatData,
			options: {
				responsive: true,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				layout: {
					padding: 0,
				},
				elements: {
					line: {
						borderWidth: 3,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					deferred: {
						// xOffset: 150, // defer until 150px of the canvas width are inside the viewport
						yOffset: 120, // defer until 100px of the canvas height are inside the viewport
						delay: 500, // delay of 500 ms after the canvas is considered inside the viewport
					},
				},
				scales: {
					// <-- Note change in options from scale to scales
					r: {
						angleLines: {
							color: "#878786",
						},
						grid: {
							circular: true,
							color: "#878786",
						},
						beginAtZero: true,
						pointLabels: {
							color: "#000",
							font: {
								size: getFontSize(),
								weight: 500,
							},
						},
						ticks: {
							display: false,
						},
					},
				},
			},
		};

		const ctx = document.getElementById("myChart");

		if (DMCommerce.chart) {
			DMCommerce.chart.destroy();
		}

		DMCommerce.chart = new Chart(ctx, config);
	}

	static renderSection2Description(title = "", descriptions = []) {
		const header = document.querySelector(".section-2 .details h2");
		const paragraph1 = document.querySelector(".section-2 .details h2+p");
		const readMoreSection = document.querySelector(
			".section-2 .read-more-target"
		);

		header.innerHTML = title;
		paragraph1.innerHTML = descriptions[0];
		readMoreSection.innerHTML = descriptions[1];

		renderReadMore(".chart-section");
	}

	// section - 3
	static renderSection3(imageUrl, headline) {
		const imageNode = document.querySelector(
			".section-3 .section-3-product-img img"
		);
		const headlingNode = document.querySelector(".section-3 h1");

		imageNode.src = imageUrl;
		headlingNode.innerHTML = headline;
	}

	// section - 4
	static renderSection4(title, description) {
		const titleNode = document.querySelector(".section-4 h3");
		const descriptionNode = document.querySelector(".section-4 p");

		titleNode.innerHTML = title;
		descriptionNode.innerHTML = description;
	}

	// section - 5
	static renderSection5({ image = ""}) {
		// web
		const imageNode = document.querySelector(".section-5 > img");
		imageNode.src = image;
	}

	// section - 6
	static renderSection6({
		image,
		title,
		descriptions = [],
		btnText,
		btnLink,
	}) {
		const imageNode1 = document.querySelector(".section-6 .section-6-img");
		const titleNode = document.querySelector(".section-6 h2");
		const paragraphNode = document.querySelector(".section-6 .details h2+p");
		const readMoreNode = document.querySelector(
			".section-6 .read-more-target"
		);
		const btnNode = document.querySelector(".section-6 .section-6-button");

		imageNode1.src = image;

		titleNode.innerHTML = title;
		paragraphNode.innerHTML = descriptions[0];
		readMoreNode.innerHTML = descriptions[1];
		btnNode.innerHTML = btnText;
		btnNode.href = btnLink;
		btnNode.dataset.link = btnLink;

		if(descriptions[1].length) {
			document.querySelector(".section-6 .read-more-button").style.display = "";
		} else {
			document.querySelector(".section-6 .read-more-button").style.display = "none";
		}

		renderReadMore(".section-6");
	}
}

export { AOS };

export default DMCommerce;